import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext.jsx";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import { LOGIN } from "../utils/routeNames.js";
import * as RouteNames from "../utils/routeNames.js";


function UserSidebar() {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 1000 * 60 * 10);
    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    localStorage.clear("auth");
    localStorage.clear("token");
    setAuth({ user: null, token: "" });
    toast.success("Logout successfully");
    navigate(LOGIN);
  };

  // Handle window resize to determine mobile screen size
  const handleResize = () => {
    setIsMobile(window.innerWidth < 400); // Update threshold for mobile view (< 400px)
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <aside
      className={`main-sidebar sidebar-light-olive elevation-4 d-flex flex-column ${
        isSidebarOpen ? "d-block" : "d-none"
      } ${isMobile ? "sidebar-mobile" : "d-md-flex"}`}
      style={{ height: "100vh", position: "fixed", zIndex: 999 }}
    >
      {/* Mobile Toggle Button */}
      {isMobile && (
        <button
          className="btn btn-primary d-block d-md-none m-3"
          type="button"
          onClick={toggleSidebar}
        >
          <i className="fas fa-bars"></i> {/* Hamburger Icon */}
        </button>
      )}

      {/* Brand Logo */}
      <a href="#" className="brand-link text-dark">
        <img
          src="/img/excelytech-logo.png"
          alt="excelytech-logo"
          className="brand-image"
        />
      </a>

      <div className="sidebar flex-grow-1">
        {/* Sidebar user panel */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="info">
            <NavLink to={"clientprofile"} className="d-block text-dark">
              <strong>{auth?.user?.name || "Welcome Client"}</strong>
            </NavLink>
          </div>
        </div>

        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column text-dark"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            <li className="nav-item">
              <NavLink
                to={RouteNames.USER_HOME}
                className={({ isActive }) =>
                  isActive ? "nav-link active text-light" : "nav-link text-dark"
                }
              >
                <i className="nav-icon fas fa-tachometer-alt"></i>
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={RouteNames.SUBSCRIPTIONS}
                className={({ isActive }) =>
                  isActive ? "nav-link active text-light" : "nav-link text-dark"
                }
              >
                <i className="nav-icon fas fa-calendar-check"></i>
                <p>My Subscriptions</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={RouteNames.SERVICE_DESK}
                className={({ isActive }) =>
                  isActive ? "nav-link active text-light" : "nav-link text-dark"
                }
              >
                <i className="nav-icon fas fa-headset"></i>
                <p>Service Desk</p>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to={RouteNames.PAY_METHOD}
                className={({ isActive }) =>
                  isActive ? "nav-link active text-light" : "nav-link text-dark"
                }
              >
                <i className="nav-icon fas fa-credit-card"></i>
                <p>Payment Method</p>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to={RouteNames.USER_CHATS}
                className={({ isActive }) =>
                  isActive ? "nav-link active text-light" : "nav-link text-dark"
                }
              >
                <i className="nav-icon fas fa-credit-card"></i>
                <p>Chats</p>
              </NavLink>
            </li>
          </ul>
        </nav>
      </div>

      {/* Logout Button */}
      <div className="logout-section">
        <li className="nav-item mt-auto">
          <button
            className="btn btn-danger w-100 px-2"
            onClick={handleLogout}
            style={{ color: "black" }} // Ensure text color is black
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="18"
              fill="currentColor"
              className="bi bi-box-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"
              />
              <path
                fillRule="evenodd"
                d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"
              />
            </svg>
            Logout
          </button>
        </li>
      </div>
    </aside>
  );
}

export default UserSidebar;
