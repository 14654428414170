import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Stepper from "react-stepper-horizontal";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import { BASE_URL } from "../utils/endPointNames";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const NewRegistration = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [auth,setAuth] = useAuth();
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [selectedSubscriptionId, setSelectedSubscriptionId] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState("Credit Card");
  const [subscriptionId,setSubscriptionId] = useState([])
  const [agreed, setAgreed] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch subscription details on component mount
    const fetchSubscriptionDetails = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}/subscription/${auth?.user._id}`,
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        setSubscriptionDetails(response.data.subscriptions);
        
        console.log("Sub",response.data);
        
      } catch (error) {
        console.error("Error fetching subscription details:", error);
        alert("Failed to fetch subscription details. Please try again.");
      }
    };
    fetchSubscriptionDetails();
  }, [auth]);

  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  const validatePassword = () => {
    const isValid =
      password === confirmPassword && passwordRegex.test(password);
    setIsPasswordValid(isValid);
  };

  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        const createPasswordResponse = await axios.post(
          `${BASE_URL}/password/create`,
          {
            clientId: auth?.user._id, // Send clientId
            password:password,                // Send password
          },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            }
          }
        );
  
        if (createPasswordResponse.status === 201) {
          setPassword(createPasswordResponse.data.password);
          console.log("Password Created Successfully");
          
          setConfirmPassword("");
          setIsPasswordValid(false);
        } else {
          alert("Failed to generate password. Please try again.");
          return;
        }
        const resetPasswordResponse = await axios.patch(
          `${BASE_URL}/user/reset-password/${auth.user._id}`,
          { oldPassword, newPassword: password },
          {
            headers: {
              Authorization: `Bearer ${auth.token}`,
            },
          }
        );
        if (resetPasswordResponse.status === 200) {
          setActiveStep(activeStep + 1);
        } else {
          alert("Failed to reset password. Please try again.");
        }
      } catch (error) {
        console.error("Error during password reset:", error);
        alert("An error occurred. Please try again.");
      }
    } else if (activeStep < 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const handlePrevious = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleSubmit = async () => {
    if (activeStep === 2 && agreed && selectedSubscriptionId) {
      try {
        // Update first login status
        const res = await axios.patch(
          `${BASE_URL}/user/user/${auth?.user._id}/setFirstLoginFalse`,
          {}, // Empty data payload
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`, // Correctly place headers
            },
          }
        );
  
        console.log("User updated successfully!", res.data);
  
        // Update subscription status
        // const response = await fetch(
        //   `${BASE_URL}/subscription/update-subscription-status/${selectedSubscriptionId}`,
        //   {
        //     method: "PATCH",
        //     headers: {
        //       "Content-Type": "application/json",
        //       Authorization: `Bearer ${auth?.token}`,
        //     },
        //     body: JSON.stringify({
        //       subscriptionStatus: "active",
        //     }),
        //   }
        // );
  
        // if (!response.ok) {
        //   throw new Error("Failed to update subscription status.");
        // }
  
        // alert(`Subscription Activated! `);
        // navigate('/login')
        //add logout
        localStorage.removeItem("auth");
    localStorage.removeItem("token");
    console.log("session removed");

    // Clear the auth context
    setAuth(null);

    // Redirect to login page
    navigate("/login");
      } catch (error) {
        console.error("Error during submission", error);
        alert("An error occurred while updating the subscription.");
      }
    } else {
      alert("Please agree to the terms and conditions and select a subscription.");
    }
  };
  
  return (
    <div className="container mt-5">
      {/* Stepper */}
      <Stepper
        steps={[
          { title: "Reset Password" },
          { title: "Select Subscription" },
          { title: "Agreement & Payment" },
        ]}
        activeStep={activeStep}
        activeColor="#007bff"
      />

      <div className="card mt-4">
        <div className="card-body">
          {activeStep === 0 && (
            <div>
              <h3>Step 1: Reset Password</h3>
              <div className="form-group">
                <label>Old Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
              </div>
              <div className="form-group">
      <label>New Password</label>
      <div className="input-group">
        <input
          type={showPassword ? "text" : "password"}
          className="form-control"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onBlur={validatePassword}
        />
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={() => setShowPassword((prev) => !prev)}
        >
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </button>
      </div>
    </div>
              <div className="form-group">
                <label>Confirm Password</label>
                <input
                  type="password"
                  className="form-control"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  onBlur={validatePassword}
                />
                {!isPasswordValid && confirmPassword.length > 0 && (
                  <small className="text-danger">
                    Passwords do not match or are invalid!
                  </small>
                )}
              </div>
              <button
                className="btn btn-success mt-3"
                disabled={!isPasswordValid}
                onClick={handleNext}
              >
                Next
              </button>
            </div>
          )}

{activeStep === 1 && (
  <div>
    <h3>Step 2: Select Subscription</h3>
    {subscriptionDetails?.length > 0 ? (
      subscriptionDetails?.map((sub) => (
        <div key={sub.id} className="form-check">
          <input
            type="radio"
            className="form-check-input"
            id={`subscription-${sub.id}`}
            name="subscription"
            value={sub.id}
            checked={selectedSubscriptionId === sub._id}
            onChange={() => setSelectedSubscriptionId(sub._id)}
          />
          <label
            className="form-check-label"
            htmlFor={`subscription-${sub._id}`}
          >
            <strong>Products:</strong>{" "}
            {sub.products.map((product) => product.productId.name).join(", ")} <br />
            <strong>Subscription Duration:</strong>{" "}
            {sub.subscriptionDurationInMonths} months<br />
            <strong>Grand Total:</strong>{" "}
            {sub.grandTotalCurrency} {sub.grandTotal}<br />
          </label>
        </div>
      ))
    ) : (
      <p>Loading subscriptions...</p>
    )}
    <button
      className="btn btn-primary mt-3"
      disabled={!selectedSubscriptionId}
      onClick={handleNext}
    >
      Next
    </button>
    {/* <button
      className="btn btn-secondary mt-3 ml-2"
      onClick={handlePrevious}
    >
      Previous
    </button> */}
  </div>
)}


{activeStep === 2 && (
  <div>
    <h3>Step 3: Agreement & Payment</h3>
    <div className="form-group">
      <label>Payment Method</label>
      <select
        className="form-control"
        value={paymentMethod}
        onChange={(e) => setPaymentMethod(e.target.value)}
      >
        <option value="null">Select</option>
        <option value="credit_card">Credit Card</option>
        <option value="debit_card">Debit Card</option>
      </select>
    </div>

    {/* Conditional Payment Form */}
    {(paymentMethod === "credit_card" || paymentMethod === "debit_card") && (
      <div className="payment-form mt-3">
        <div className="form-group">
          <label>Card Number</label>
          <input
            type="text"
            className="form-control"
            maxLength="16"
            placeholder="Enter your card number"
          />
        </div>
        <div className="form-row">
          <div className="form-group col-md-6">
            <label>Expiry Date</label>
            <input
              type="text"
              className="form-control"
              placeholder="MM/YY"
            />
          </div>
          <div className="form-group col-md-6">
            <label>CVV</label>
            <input
              type="text"
              className="form-control"
              maxLength="3"
              placeholder="Enter CVV"
            />
          </div>
        </div>
      </div>
    )}

    <div className="form-group mt-3">
      <div className="custom-control custom-checkbox">
        <input
          type="checkbox"
          className="custom-control-input"
          id="agreeCheckbox"
          checked={agreed}
          onChange={() => setAgreed(!agreed)}
        />
        <label className="custom-control-label" htmlFor="agreeCheckbox">
          I agree to the terms and conditions
        </label>
      </div>
    </div>

    <button
      className="btn btn-success"
      disabled={!agreed || !selectedSubscriptionId}
      onClick={handleSubmit}
    >
      Activate Subscription
    </button>
    <button
      className="btn btn-secondary ml-2"
      onClick={handlePrevious}
    >
      Previous
    </button>
  </div>
)}

        </div>
      </div>
    </div>
  );
};

export default NewRegistration;
