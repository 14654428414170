import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { BASE_URL } from "../../utils/endPointNames";
import moment from "moment";

function View() {
  const [viewInfo, setViewInfo] = useState(null);
  const [auth] = useAuth();
  const { id } = useParams();
  const [notes, setNotes] = useState([]);
  const [noteContent, setNoteContent] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const notesPerPage = 4; // Set number of notes per page

  // Function to fetch user details
  const viewpage = async () => {
    try {
      const res = await axios.get(`${BASE_URL}/user/${id}`, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });
      setViewInfo(res.data);
      // console.log("viewInfo", res.data);

      setNotes(res.data.notes || []); // Initialize notes from the fetched data
    } catch (error) {
      console.error(error);
    }
  };

  // Function to handle adding new note
  const handleAddNote = async (e) => {
    e.preventDefault();

    try {
      const newNote = {
        content: noteContent,
        noteMadeBy: auth?.user._id,
        createdAt: new Date().toISOString(),
      };

      const updatedUser = {
        ...viewInfo,
        notes: [newNote, ...(viewInfo?.notes || [])],
      };

      const res = await axios.patch(`${BASE_URL}/user/${id}`, updatedUser, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      const sortedNotes = res.data.notes.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setViewInfo(res.data);
      setNotes(sortedNotes); // Update local notes array with sorted notes
      setNoteContent(""); // Clear the textarea
      setCurrentPage(1); // Reset to the first page
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };
  useEffect(() => {
    if (auth?.token) {
      viewpage();
    }
  }, [auth]);

  // Get notes for current page
  const indexOfLastNote = currentPage * notesPerPage;
  const indexOfFirstNote = indexOfLastNote - notesPerPage;
  const currentNotes = notes.slice(indexOfFirstNote, indexOfLastNote);

  // Pagination handlers
  const totalPages = Math.ceil(notes.length / notesPerPage);

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <>
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-dark">User Details</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="#">Home</a>
                  </li>
                  <li className="breadcrumb-item active">User Details</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <div className="row">
            {/* User Info Card */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header ">
                  <h3 className="card-title">Account Owner</h3>
                </div>
                <div className="card-body">
                  <dl className="row">
                    <dt className="col-sm-4">Name:</dt>
                    <dd className="col-sm-8">{viewInfo?.name}</dd>

                    <dt className="col-sm-4">Email:</dt>
                    <dd className="col-sm-8">{viewInfo?.email}</dd>

                    <dt className="col-sm-4">Phone:</dt>
                    <dd className="col-sm-8">{viewInfo?.phone}</dd>

                    <dt className="col-sm-4">User Type:</dt>
                    <dd className="col-sm-8">{viewInfo?.userType}</dd>

                    <dt className="col-sm-4">Account Manager:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.accountManagers?.name || "Not Assigned"}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            {/* Address Card */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header ">
                  <h3 className="card-title">Address </h3>
                </div>
                <div className="card-body">
                  <dl className="row">
                    <dt className="col-sm-4">Street 1:</dt>
                    <dd className="col-sm-8">{viewInfo?.address?.street1}</dd>

                    <dt className="col-sm-4">Street 2:</dt>
                    <dd className="col-sm-8">{viewInfo?.address?.street2}</dd>

                    <dt className="col-sm-4">City:</dt>
                    <dd className="col-sm-8">{viewInfo?.address?.city}</dd>

                    <dt className="col-sm-4">State:</dt>
                    <dd className="col-sm-8">{viewInfo?.address?.state}</dd>

                    <dt className="col-sm-4">Zip Code:</dt>
                    <dd className="col-sm-8">{viewInfo?.address?.zipCode}</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>

          {/* Business Details Card */}
          <div className="row">
            <div className="col-md-6">
              <div className="card">
                <div className="card-header ">
                  <h3 className="card-title">Business Information</h3>
                </div>
                <div className="card-body">
                  <dl className="row">
                    <dt className="col-sm-4">Client Name:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.clientName}
                    </dd>

                    <dt className="col-sm-4">Company Name:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.companyName}
                    </dd>

                    <dt className="col-sm-4">Company Type:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.companyType}
                    </dd>

                    <dt className="col-sm-4">Tax ID:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.taxId}
                    </dd>

                    <dt className="col-sm-4">Employee Size:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.employeeSize}
                    </dd>

                    <dt className="col-sm-4">Owner Phone:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.ownerPhone}
                    </dd>

                    <dt className="col-sm-4">Owner Email:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.businessDetails?.ownerEmail}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            {/* Miscellaneous Info Card */}
            <div className="col-md-6">
              <div className="card">
                <div className="card-header ">
                  <h3 className="card-title">Account Status</h3>
                </div>
                <div className="card-body">
                  <dl className="row">
                    <dt className="col-sm-4">Account Manager:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.accountManagers?.name || "Not Assigned"}
                    </dd>
                    <dt className="col-sm-4">Allow Login:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.allowLogin ? "Yes" : "No"}
                    </dd>

                    <dt className="col-sm-4">Account Active:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.activeAccount ? "Yes" : "No"}
                    </dd>

                    {/* <dt className="col-sm-4">Account Banned:</dt>
                    <dd className="col-sm-8">
                      {viewInfo?.bannedAccount ? "Yes" : "No"}
                    </dd> */}
                  </dl>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card">
              <div className="card-header bg-olive">
                <h3 className="card-title">Notes</h3>
              </div>
              <div className="row">
                {/* Left Section: Display Notes */}
                <div className="col-md-6">
                  <div
                    className="card-body h-100 overflow-auto"
                    style={{ maxHeight: "400px" }}
                  >
                    {currentNotes.length > 0 ? (
                      currentNotes.map((note, index) => (
                        <div className="card mb-3" key={index}>
                          <div className="card-body">
                            <p className="card-text">{note.content}</p>
                            <small className="text-muted d-block text-end">
                              {moment(note.createdAt).format(
                                "HH:mm - DD, MMMM YYYY"
                              )}
                            </small>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No notes available</p>
                    )}

                    {/* Pagination Controls */}
                    <div className="d-flex justify-content-between mt-3">
                      <button
                        className="btn btn-outline-primary"
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                      >
                        Previous
                      </button>
                      <span>
                        Page {currentPage} of {totalPages}
                      </span>
                      <button
                        className="btn btn-outline-primary"
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>

                {/* Right Section: Add New Note */}
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <form onSubmit={handleAddNote}>
                        <div className="form-group">
                          <label htmlFor="noteContent">Add New Note</label>
                          <textarea
                            id="noteContent"
                            className="form-control"
                            placeholder="Enter note content"
                            rows="5"
                            value={noteContent}
                            onChange={(e) => setNoteContent(e.target.value)}
                            required
                          ></textarea>
                        </div>
                        <button type="submit" className="btn btn-success">
                          Add Note
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default View;
