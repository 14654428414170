import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import {
  ALL_PRODUCTS,
  ALL_PROPOSALS,
  CATEGORYS,
  LOGIN,
  PROPOSAL_TEMPLATES,
} from "../../utils/routeNames";

const AdminSidebar = () => {
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const location = useLocation();
  const [expandedMenu, setExpandedMenu] = useState(null); // Track which menu is expanded

  const checkTokenExpiration = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decodedToken.exp < currentTime) {
        handleLogout();
      }
    }
  };

  useEffect(() => {
    checkTokenExpiration();
    const intervalId = setInterval(checkTokenExpiration, 1000 * 60 * 10);
    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = () => {
    localStorage.clear("auth");
    localStorage.clear("token");
    setAuth({ user: null, token: "" });
    toast.success("Logout successfully");
    navigate(LOGIN);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleMenu = (menu) => {
    setExpandedMenu(menu); //(prev) => (prev === menu ? null : menu)); // Toggle the current menu
    // console.log("menu", expandedMenu);
  };

  return (
    <>
      {/* Mobile Toggle Button */}
      <button
        className="btn btn-primary d-md-none"
        onClick={toggleSidebar}
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          zIndex: 1000,
        }}
      >
        <i className="fas fa-bars"></i>
      </button>

      <aside
        className={`main-sidebar sidebar-light-olive elevation-4 d-flex flex-column ${
          isSidebarOpen ? "d-block" : "d-none d-md-flex"
        }`}
        style={{ height: "100vh", position: "fixed", zIndex: 999 }}
      >
        {/* Brand Logo */}
        <a href="#" className="brand-link">
          <img
            src="/img/excelytech-logo.png"
            alt="excelytech-logo"
            className="brand-image"
          />
        </a>

        <div className="sidebar flex-grow-1">
          {/* Sidebar user panel */}
          <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="info">
              <a href="#" className="d-block">
                Admin
              </a>
            </div>
          </div>

          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {/* Dashboard */}
              <li className="nav-item">
                <NavLink
                  to="home"
                  className="nav-link"
                  onClick={() => {
                    toggleMenu(null);
                  }}
                >
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>Dashboard</p>
                </NavLink>
              </li>

              {/* Account Centre */}
              <li className="nav-item">
                <NavLink
                  to="allusers"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    toggleMenu(null);
                  }}
                >
                  <i className="nav-icon fas fa-users"></i>
                  <p>Account Centre</p>
                </NavLink>
              </li>

              {/* Product Suite */}
              <li
                className={`nav-item has-treeview ${
                  expandedMenu === "productSuite" ? "menu-open" : ""
                }`}
              >
                <a
                  href={CATEGORYS}
                  className={`nav-link ${
                    expandedMenu === "productSuite" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleMenu("productSuite");
                  }}
                >
                  <i className="nav-icon fas fa-box"></i>
                  <p>
                    Product Suite
                    <i
                      className={`right fas fa-angle-left ${
                        expandedMenu === "productSuite" ? "rotate-90" : ""
                      }`}
                    ></i>
                  </p>
                </a>
                <ul
                  className={`nav nav-treeview ${
                    expandedMenu === "productSuite" ? "" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink
                      to={CATEGORYS}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="fas fa-th-list nav-icon"></i>
                      <p>Categories</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={ALL_PRODUCTS}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="fas fa-gift nav-icon"></i>
                      <p>Products</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* subscriptions */}
              <li className="nav-item">
                <NavLink
                  to="subscriptions"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    toggleMenu(null);
                  }}
                >
                  <i className="nav-icon fas fa-shopping-cart"></i>
                  <p>Subscriptions</p>
                </NavLink>
              </li>
              {/* Leads and Marketing */}
              <li
                className={`nav-item has-treeview ${
                  expandedMenu === "leadsAndMarketing" ? "menu-open" : ""
                }`}
              >
                <a
                  href={ALL_PROPOSALS}
                  className={`nav-link ${
                    expandedMenu === "leadsAndMarketing" ? "active" : ""
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    toggleMenu("leadsAndMarketing");
                  }}
                >
                  <i className="nav-icon fas fa-file-signature"></i>
                  <p>
                    Leads and Quotes
                    <i
                      className={`right fas fa-angle-left ${
                        expandedMenu === "leadsAndMarketing" ? "rotate-90" : ""
                      }`}
                    ></i>
                  </p>
                </a>
                <ul
                  className={`nav nav-treeview ${
                    expandedMenu === "leadsAndMarketing" ? "" : "d-none"
                  }`}
                >
                  <li className="nav-item">
                    <NavLink
                      to={ALL_PROPOSALS}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="fas fa-clipboard-list nav-icon"></i>
                      <p>All Proposals</p>
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={PROPOSAL_TEMPLATES}
                      className={({ isActive }) =>
                        isActive ? "nav-link active" : "nav-link"
                      }
                    >
                      <i className="fas fa-file-alt nav-icon"></i>
                      <p>Proposal Templates</p>
                    </NavLink>
                  </li>
                </ul>
              </li>

              {/* Service Desk */}
              <li className="nav-item">
                <NavLink
                  to="tickets"
                  className={({ isActive }) =>
                    isActive ? "nav-link active" : "nav-link"
                  }
                  onClick={() => {
                    toggleMenu(null);
                  }}
                >
                  <i className="nav-icon fas fa-ticket-alt"></i>
                  <p>Service Desk</p>
                </NavLink>
              </li>
              {/* <li className="nav-item">
                <NavLink to="chats" className="nav-link">
                  <i className="nav-icon fas fa-shopping-cart"></i>
                  <p>Chat</p>
                </NavLink>
              </li> */}
            </ul>
          </nav>
        </div>

        {/* Logout Button */}
        <div className="logout-section">
          <li className="nav-item mt-auto">
            <button className="btn btn-danger w-100" onClick={handleLogout}>
              <i className="fas fa-sign-out-alt"></i> Logout
            </button>
          </li>
        </div>
      </aside>
    </>
  );
};

export default AdminSidebar;
