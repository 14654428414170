import React, { useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../utils/endPointNames";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

function AddPaymentMethod() {
  const [methodType, setMethodType] = useState("");
  const [cardDetails, setCardDetails] = useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
  });
  const [bankDetails, setBankDetails] = useState({
    accountNumber: "",
    bankName: "",
    ifscCode: "",
  });
  const [upiDetails, setUpiDetails] = useState({ upiId: "" });
  const [paypalDetails, setPaypalDetails] = useState({
    paypalEmail: "",
    payerId: "",
  });
  const [showDefault, setShowDefault] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [auth] = useAuth();
  // Replace with your actual base URL

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = {
      clientId: auth?.user._id,
      methodType,
      cardDetails: methodType.includes("card") ? cardDetails : "",
      bankDetails: methodType === "bank_account" ? bankDetails : "",
      upiDetails: methodType === "upi" ? upiDetails : "",
      paypalDetails: methodType === "paypal" ? paypalDetails : "",
      isDefault,
    };

    try {
      // If `isDefault` is true, reset all other methods for this client
      if (isDefault) {
        await axios.patch(
          `${BASE_URL}/paymentMethod/default`,
          { clientId: auth?.user._id },
          {
            headers: {
              Authorization: `Bearer ${auth?.token}`,
            },
          }
        );
        // console.log("Res",response);
      }

      // Add the new payment method
      const res = await axios.post(`${BASE_URL}/paymentMethod/create`, data, {
        headers: {
          Authorization: `Bearer ${auth?.token}`,
        },
      });

      alert("Payment method added successfully!");
      setLoading(false);
      navigate(-1); // Redirect to the previous page
    } catch (error) {
      console.error("Error adding payment method:", error);
      alert(error.res.data?.message || "Failed to add payment method.");
      setLoading(false);
    }
  };

  const renderFormFields = () => {
    switch (methodType) {
      case "credit_card":
      case "debit_card":
        return (
          <>
            <div className="form-group">
              <label>
                Card Number{" "}
                <small className="text-muted">(e.g., 1234567812345678)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter 16-digit card number"
                maxLength="16"
                pattern="^[0-9]{16}$"
                value={cardDetails.cardNumber}
                onChange={(e) =>
                  setCardDetails({ ...cardDetails, cardNumber: e.target.value })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Card Number must be exactly 16 digits (e.g., 1234567812345678)."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="form-group">
              <label>
                Expiry Date <small className="text-muted">(e.g., 09/25)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="MM/YY"
                pattern="^(0[1-9]|1[0-2])\/\d{2}$"
                value={cardDetails.expiryDate}
                onChange={(e) =>
                  setCardDetails({ ...cardDetails, expiryDate: e.target.value })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Expiry Date must follow the MM/YY format (e.g., 09/25)."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="form-group">
              <label>
                CVV <small className="text-muted">(e.g., 123 or 1234)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter CVV"
                maxLength="4"
                pattern="^[0-9]{3,4}$"
                value={cardDetails.cvv}
                onChange={(e) =>
                  setCardDetails({ ...cardDetails, cvv: e.target.value })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "CVV must be 3 or 4 digits (e.g., 123 or 1234)."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
          </>
        );
      case "bank_account":
        return (
          <>
            <div className="form-group">
              <label>
                Account Number{" "}
                <small className="text-muted">(e.g., 1234567890)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter account number"
                pattern="^[0-9]+$"
                value={bankDetails.accountNumber}
                onChange={(e) =>
                  setBankDetails({
                    ...bankDetails,
                    accountNumber: e.target.value,
                  })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Account Number must contain only numbers."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="form-group">
              <label>
                Bank Name{" "}
                <small className="text-muted">
                  (e.g., State Bank of India)
                </small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter bank name"
                value={bankDetails.bankName}
                onChange={(e) =>
                  setBankDetails({ ...bankDetails, bankName: e.target.value })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity("Please enter a valid bank name.")
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="form-group">
              <label>
                IFSC Code{" "}
                <small className="text-muted">(e.g., SBIN0001234)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter IFSC code"
                pattern="^[A-Za-z]{4}\d{7}$"
                value={bankDetails.ifscCode}
                onChange={(e) =>
                  setBankDetails({ ...bankDetails, ifscCode: e.target.value })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "IFSC Code must follow the pattern: 4 letters followed by 7 digits (e.g., SBIN0001234)."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
          </>
        );
      case "upi":
        return (
          <div className="form-group">
            <label>
              UPI ID <small className="text-muted">(e.g., username@bank)</small>
            </label>
            <input
              type="text"
              className="form-control"
              placeholder="Enter UPI ID"
              pattern="^[a-zA-Z0-9.\-_]{2,}@[a-zA-Z]{2,}$"
              value={upiDetails.upiId}
              onChange={(e) =>
                setUpiDetails({ ...upiDetails, upiId: e.target.value })
              }
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "UPI ID must be in the format username@bank (e.g., john.doe@hdfc)."
                )
              }
              onInput={(e) => e.target.setCustomValidity("")}
              required
            />
          </div>
        );
      case "paypal":
        return (
          <>
            <div className="form-group">
              <label>
                PayPal Email{" "}
                <small className="text-muted">(e.g., example@email.com)</small>
              </label>
              <input
                type="email"
                className="form-control"
                placeholder="Enter PayPal email"
                value={paypalDetails.paypalEmail}
                onChange={(e) =>
                  setPaypalDetails({
                    ...paypalDetails,
                    paypalEmail: e.target.value,
                  })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Please enter a valid email address (e.g., example@email.com)."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
            <div className="form-group">
              <label>
                Payer ID{" "}
                <small className="text-muted">(e.g., 123456789ABCDEF)</small>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Payer ID"
                pattern="^[a-zA-Z0-9]+$"
                value={paypalDetails.payerId}
                onChange={(e) =>
                  setPaypalDetails({
                    ...paypalDetails,
                    payerId: e.target.value,
                  })
                }
                onInvalid={(e) =>
                  e.target.setCustomValidity(
                    "Payer ID must contain only alphanumeric characters."
                  )
                }
                onInput={(e) => e.target.setCustomValidity("")}
                required
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container  pt-5">
        <div className="card">
          <div className="card-header bg-primary text-white">
            <h4>Add Payment Method</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label>Payment Method Type</label>
                <select
                  className="form-control"
                  value={methodType}
                  onChange={(e) => setMethodType(e.target.value)}
                  required
                >
                  <option value="">Select Method</option>
                  <option value="credit_card">Credit Card</option>
                  <option value="debit_card">Debit Card</option>
                  {/* <option value="bank_account">Bank Account</option>
                  <option value="upi">UPI</option>
                  <option value="paypal">PayPal</option> */}
                </select>
              </div>
              {renderFormFields()}
              <div className="form-check mt-3">
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={isDefault}
                  onChange={() => setIsDefault(!isDefault)}
                />
                <label className="form-check-label">Set as Default</label>
              </div>
              <button
                type="submit"
                className="btn btn-success mt-4"
                disabled={loading}
              >
                {loading ? "Adding..." : "Add Payment Method"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPaymentMethod;
