import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../context/AuthContext";
import { BASE_URL } from "../utils/endPointNames";
import { io } from "socket.io-client";
import axios from "axios";

const ChatSidebar = () => {
  const [messages, setMessages] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [socket, setSocket] = useState(null);
  const messagesEndRef = useRef(null); // Reference for auto-scroll
  const newUrl = BASE_URL.replace("/api", "");
  const [auth] = useAuth();
  const [file, setFile] = useState(null)

  // Utility: Format date as "Day, DD MMM YYYY"
  const formatDate = (date) =>
    new Date(date).toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
    });

  // Utility: Check if the current message starts a new day
  const isNewDay = (currentMessage, index) => {
    if (index === 0) return true;
    const currentDate = new Date(currentMessage.createdAt).toDateString();
    const previousDate = new Date(
      messages[index - 1]?.createdAt
    ).toDateString();
    return currentDate !== previousDate;
  };

  useEffect(() => {
    // Fetch users and messages
    const fetchUsers = async () => {
      if (auth?.user?.role === "admin") {
        try {
          const res = await axios.get(`${BASE_URL}/user/users`, {
            headers: { Authorization: `Bearer ${auth?.token}` },
          });
          setUsers(res.data.data);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      }
    };

    fetchUsers();

    const newSocket = io(newUrl, {
      extraHeaders: { token: auth?.token },
    });

    newSocket.on('connected',
      console.log("connetd")
    )
    setSocket(newSocket);

    return () => {
      newSocket.disconnect();
    };
  }, [auth]);

  useEffect(() => {
    // Join room and handle incoming messages
    const userId = auth?.user._id;
    const receiverId =
      auth?.user?.role === "client"
        ? auth?.user?.accountManagers._id
        : selectedUser?._id;

    if (receiverId && socket) {
      socket.emit("joinRoom", { userId, receiverId });

      socket.on("previousMessages", (fetchedMessages) => {
        setMessages(fetchedMessages);
        
        
      });

      socket.on("receiveMessage", (message) => {
        setMessages((prevMessages) => [...prevMessages, message]);
      });
    }

    return () => {
      if (socket) {
        socket.off("previousMessages");
        socket.off("receiveMessage");
      }
    };
  }, [selectedUser, socket, auth]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const sendMessage = async () => {
    const receiverId =
      auth?.user?.role === "client"
        ? auth?.user?.accountManagers
        : selectedUser?._id;

    if ((!newMessage.trim() && !file) || !receiverId) return;

    const messageData = {
      sender: auth?.user._id,
      receiver: receiverId,
      message: newMessage,
      createdAt: new Date(),
    };

    // Handle file upload
    if (file) {
      const formData = new FormData();
      formData.append("image", file);

      try {
        const res = await axios.post(`${BASE_URL}/upload/productImage`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${auth?.token}`,
          },
        });
        console.log("file",res.data);
        
        messageData.fileUrl = res.data.fileUrl; // Attach file URL to the message
        setFile(null); // Clear the file state after upload
      } catch (error) {
        console.error("File upload failed:", error);
        return;
      }
    }

    socket.emit("sendMessage", messageData);
    setNewMessage(""); // Clear text message
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="content-wrapper">
    <section className="content pt-2">
      <div className="row" style={{ height: "calc(100vh - 50px)" }}>
        {/* Sidebar */}
        {auth?.user?.role === "admin" && (
          <div
            className="col-md-4 col-sm-12 border-end bg-light"
            style={{ height: "100%", overflowY: "scroll" }}
          >
            <div
              className="p-3 bg-olive text-white text-center mb-3"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              Clients
            </div>
            <ul className="list-group">
              {users.map((user) => (
                <li
                  key={user._id}
                  className={`list-group-item ${
                    selectedUser?._id === user._id
                      ? "active bg-olive text-white"
                      : ""
                  }`}
                  onClick={() => setSelectedUser(user)}
                  style={{ cursor: "pointer", marginBottom: "5px" }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="fw-bold">{user.name}</span>
                    {selectedUser?._id === user._id && (
                      <span className="text-success small">Active</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Chatbox */}
        <div
          className={`col-md-${
            auth?.user?.role === "admin" ? "8" : "12"
          } col-sm-12`}
          style={{ height: "100%" }}
        >
          <div className="d-flex flex-column h-100 bg-white shadow-sm rounded">
            {/* Chat Header */}
            <div
              className="p-3 bg-olive text-white"
              style={{ fontSize: "18px", fontWeight: "bold" }}
            >
              {selectedUser ? selectedUser.name : "Select a User to Chat"}
            </div>

            {/* Chat Messages */}
            <div
              className="flex-grow-1 p-3 bg-light overflow-auto"
              style={{ height: "calc(100% - 120px)" }}
            >
              {selectedUser ? (
                <>
                  {messages.map((msg, index) => (
                    <React.Fragment key={msg._id}>
                      {isNewDay(msg, index) && (
                        <div className="text-center my-2">
                          <span className="badge bg-secondary">
                            {formatDate(msg.createdAt)}
                          </span>
                        </div>
                      )}
                      <div
                        className={`d-flex ${
                          msg.sender === auth?.user._id
                            ? "justify-content-end"
                            : ""
                        }`}
                      >
                        <div
                          className={`p-2 m-1 shadow-sm rounded ${
                            msg.sender === auth?.user._id
                              ? "bg-olive text-white"
                              : "bg-light text-dark"
                          }`}
                          style={{ maxWidth: "75%" }}
                        >
                          <div className="small mb-1">
                            {msg.sender === auth?.user._id
                              ? "You"
                              : selectedUser?.name}
                          </div>
                          {msg.fileUrl ? (
                            <a
                              href={`${BASE_URL}/${msg.fileUrl}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i className="fas fa-file-alt"></i> View File
                            </a>
                          ) : (
                            <div>{msg.message}</div>
                          )}
                          <div className="small text-end text-dark mt-1">
                            {new Date(msg.createdAt).toLocaleTimeString()}
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
                </>
              ) : (
                <div
                  className="d-flex justify-content-center align-items-center text-dark h-100"
                  style={{ fontSize: "20px", fontWeight: "bold" }}
                >
                  Select a user to start chatting.
                </div>
              )}
              <div ref={messagesEndRef}></div>
            </div>

            {/* Input Box */}
            {selectedUser ? (
              <div className="p-3 border-top">
                <div className="input-group">
                  <label className="input-group-text bg-light">
                    <i className="fas fa-paperclip"></i>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type a message..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyPress={(e) =>
                      e.key === "Enter" && sendMessage()
                    }
                  />
                  <button className="btn btn-primary" onClick={sendMessage}>
                    <i className="fas fa-paper-plane"></i>
                  </button>
                </div>
                {file && (
                  <div className="mt-2 small text-secondary">
                    Attached File: {file.name}
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  </div>
  );
};

export default ChatSidebar;

